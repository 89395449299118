* {
  box-sizing: border-box;
}

html,
body,
.container {
  background-color: #f9f9f9;
  min-height: 100%;
  height: 100%;
  width: 100%;
  -webkit-print-color-adjust: exact;
  -webkit-tap-highlight-color: transparent;
}

#root {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.container {
  display: flex;
}

.bx--card {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.bx--overflow-menu-options--open:focus {
  outline: none !important;
}

.bx--modal {
  margin: 0 !important;
}

#PlacesAutocomplete__autocomplete-container {
  z-index: 1;
}

.toggle-item {
  vertical-align: middle;
  display: inline-block;
  margin-right: 8px;
}

#modal-container {
  z-index: 100000;
}

.flatpickr-calendar {
  z-index: 1000000 !important;
}

.rc-menu-submenu-popup {
  z-index: 10000 !important;
}

#select-menu-container > * {
  z-index: 999999;
}

@media screen and (max-width: 600px) {
  .bx--text-input {
    min-width: 200px !important;
  }
}

.bx--btn--green--secondary {
  border-color: #1aaa55;
  color: #1aaa55;
  background-color: white;
}

.bx--btn--green--secondary svg {
  fill: #1aaa55 !important;
}

.bx--btn--green--secondary:hover svg {
  fill: white !important;
}

.bx--btn--green--secondary:hover {
  background-color: #1aaa55;
  color: white;
}

.sr-only {
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.ril-zoom-in {
  display: none !important;
}

.ril-zoom-out {
  display: none !important;
}

.photo_share_view {
  .ril__closeButton {
    display: none !important;
  }
}

.bx--tooltip {
  font-size: 12px;
  padding: 6px;
}

.bx--link,
.bx--content-switcher-btn {
  color: #095dcf;
}

.bx--content-switcher-btn.bx--content-switcher--selected {
  background-color: #095dcf;
}

.bx--btn--primary {
  background-color: #095dcf;
}

.bx--btn--secondary {
  color: #095dcf;
  border-color: #095dcf;
}

.bx--tooltip--icon__bottom path {
  fill: #095dcf;
}
